import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

import styles from './Content.module.scss'

const query = graphql`
  query {
    placeholder: file(relativePath: { eq: "assets/images/placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600, quality: 75, toFormat: JPG) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Content = ({ children, cta, image, image_alt, title, titleBlack }) => {
  const classNames = {
    title: [styles.title, titleBlack ? styles.black : null].join(' '),
  }

  return (
    <div className={styles.content}>
      <div className={styles.imageWrapper}>
        {image ? (
          <Img alt={image_alt} className={styles.image} fluid={image} />
        ) : (
          <StaticQuery
            query={query}
            render={({ placeholder }) => (
              <Img
                alt={image_alt}
                className={styles.placeholder}
                fluid={placeholder.childImageSharp.fluid}
              />
            )}
          />
        )}
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.contentText}>
          <h1 className={classNames.title}>{title}</h1>

          {children}
        </div>

        {cta && <div className={styles.cta}>{cta()}</div>}
      </div>
    </div>
  )
}

Content.propTypes = {
  cta: PropTypes.func,
  image: PropTypes.object,
  image_alt: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleBlack: PropTypes.bool,
}

export default Content
